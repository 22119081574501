<template>
  <picker
    :documentTypes="column.options.documentType"
    :selected-id.sync="computedSelectedId"
    v-on="on"
    @click.native.stop
    hide-label
    :placeholder="$t('t.Search')"
    :clearable="column.options && column.options.clearable"
  />
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  data: function () {
    return {
      selectedId: this.value
    }
  },
  components: {
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedSelectedId: {
      get () {
        return this.selectedId
      },
      set (id) {
        this.selectedId = id
      }
    },
    on () {
      const events = {}
      if (this.column.options.on) {
        Object.entries(this.column.options.on).forEach(([eventName, fn]) => {
          events[eventName] = event => fn(event, this.itemId)
        })
      }
      return events
    }
  },
  props: {
    value: {
      type: [Object, String]
    },
    itemId: {
      type: [String]
    },
    column: Column
  }
}
</script>
